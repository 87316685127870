<template>
    <div class="index-content">
        <div class="brief">
            <p class="brief-title">考级简介</p>
            <p class="brief-p">美术考级是社会艺术水平考级的一个重要组成部分，对于普及艺术教育、提高广大青少年及人民群众的美术修养，提高全民素质具有十分重要的意义。随着美术考级别活动的不断深入，社会各界对美术考级给予了充分的肯定和高度的评价，并积极参与到这项活动中来。</p>
            <p class="brief-name">什么是社会美术考级?</p>
            <p class="brief-p">中国美术学院成立于1928年，是由卓越的教育家蔡元培、林风眠先生所创建的我国第- -所综合性的国立高等艺术学府。中国美术学院原名“国立艺术院”，经过了八十多年的发展，现已成为当今国内学科最完备、规模最齐整的综合型美术学院之一。中国美院现有教师500余人，其中有正高职称76人，副高职称138人，中级职称177人。因此，社会美术水平考级的评审工作才由中国美术学院负责，它的权威性也是无庸质疑的。</p>
            <p class="brief-name">中国美术学院是一所怎样的美术院校?</p>
            <p class="brief-p">中国美术学院成立于1928年，是由卓越的教育家蔡元培、林风眠先生所创建的我国第- -所综合性的国立高等艺术学府。中国美术学院原名“国立艺术院”，经过了八十多年的发展，现已成为当今国内学科最完备、规模最齐整的综合型美术学院之- -。中国美院现有教师500余人，其中有正高职称76人，副高职称138人，中级职称177人。因此，社会美术水平考级的评审工作才由中国美术学院负责，它的权威性也是无庸质疑的。</p>
            <p class="brief-name">为什么要参加美术考级?</p>
            <p class="brief-p">通过参加美术考级，广大美术爱好者可以对自己的水平有一个全面的了解，今后的学习就能更有针对性，提高效率。对于准备
报考专业类美术院校的考生来说，通过参加美术考级，能够对自己的美术水平有-个全面客观的认识。如果考生能通过9级的
话，那么他已达到美术专业大学本科一年级的水平。通过参加美术考级，能够提高广大考生的自信心，更有针对性的准备考
</p>
            <p class="brief-name">美术考级都包括那些科目?</p>
            <p class="brief-p">美术考级共设十个科目，分别是素描、水彩、水粉、硬笔书法、软笔书法、中国山水画、中国花鸟画、中国人物画、速写、动
漫。每个科目分又分1-9级，其中1- 3级为初级，4- 6级为中级，7-9级为高级。
如何报名参加社会美术水平考级?
采用全市统-报名、集中考试的方式每年在寒、暑假期间分两次进行，具体报名和考试时间届时见通知。
</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'brief',
    methods: {
    }
}
</script>
<style lang="scss" scoped>
    .index-content{
        background: #F6F6EA;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .brief{
        width: 1200px;
        background: #ffffff;
        padding: 60px 40px;
    }
    .brief-title{
        font-weight: 600;
        color: #333333;
        font-size: 30px;
        padding-bottom: 40px;
        text-align: center;
    }
    .brief-name{
        font-weight: bold;
        padding: 50px 0;
    }
    .brief-p{
        text-indent: 2em;
        line-height: 25px;
    }
</style>